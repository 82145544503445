import './App.css';

import logo from '../src/assets/logo-qpersonal.png'
import play_store from '../src/assets/play-badge.png'
import app_store from '../src/assets/apple-store.svg'


function App() {
  
   const link ='qpersonalapp://q.personal.app/accept-group-invitation'+window.location.search;
    
  
  return (
    <main className="p-5 bg-black min-h-screen  text-white ">
      <div className="flex flex-col text-center">
        <img src={logo} alt="Q Personal Logo" className='max-w-xs w-24 mx-auto' />
        <h1 className='text-white'>
          Bem vindo ao +QPersonal

        </h1>      

        <h3>
          Se você já tem o app instalado, clique no botão abaixo:
        </h3>
        <a href={link}>
        <button className="bg-brand-primary p-3 rounded-lg max-w-sm mx-auto w-64 text-base cursor-pointer">
          Acessar o app
        </button>
        </a>
        <h4>
          Ainda não instalou o app? baixe agora mesmo!
        </h4>
        
        <img src={play_store} alt="Q Personal Logo" className='max-w-xs w-44 mx-auto cursor-pointer' />
        <img src={app_store} alt="Q Personal Logo" className='max-w-xs w-44 mx-auto cursor-pointer' />
        <h4>
          Depois de concluir a instalação do app no seu celular, volte a esta página e clique no botão abaixo:
        </h4>
        <a href={link}>
        <button className="bg-brand-primary p-3 rounded-lg max-w-sm mx-auto w-64 text-base cursor-pointer">
          Acessar o app
        </button>
        </a>
        </div>
    </main>
  );
}

export default App;
